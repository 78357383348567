.product-main {
  display: flex;
  justify-content: space-around;
}

.product-go-back {
  text-align: center;
}

.product-content {
  width: 25%;
  height: 240px;
  line-height: 60px;
  text-align: center;
  box-sizing: border-box;
  /* border: 1px solid #2979FF; */
  box-shadow: 0px 0px 10px gainsboro;
  border-radius: 10px;
  margin-top: 15vh;
}

.product-name {
  font-size: 20px;
  margin: 0 auto;
  cursor: pointer;
  border-radius: 8px;
  border-radius: 4px;
  background: #FFFFFF;
  color: #2979FF;
  font-family: Source Han Sans SC;
  font-weight: bold;
  font-size: 38px;
  line-height: unset;
  letter-spacing: 0.67px;
  text-align: left;
  width: 180px;
  margin-top: 35px;
}

.product-en-name {
  font-family: Futura;
  font-weight: bold;
  font-size: 38px;
  line-height: normal;
  letter-spacing: 0.67px;
  text-align: left;
  color: transparent;
  -webkit-text-stroke: 1px #2979FF;
  position: absolute;
  margin-top: 70px;
  width: 25%;
}

.product-en-name-title {
  margin: 0 auto;
  text-align: center;
  opacity: 0.4;
}

.product-button {
  width: 40%;
  margin: 20px auto 0;
  height: 40px;
  border-radius: 60px;
  background: #2979FF;
  color: #ffffff;
  line-height: 40px;
}

.go-back-button {
  width: 20%;
  margin: 10vh auto 0;
  height: 40px;
  border-radius: 60px;
  background: #2979FF;
  color: #ffffff;
  line-height: 40px;
  text-align: center;
}