body {
  height: 100vh;
  margin: 0;
}

.dots {
  display: none;
}

.dictation-text-content {
  position: absolute;
  top: 100px;
  right: 20px;
  background-color: white;
  color: #2979ff;
  z-index: 1;
  max-width: 300px;
}
.dictation-text {
  max-height: 100px;
  overflow-y: auto;
  white-space: normal;
  word-break: break-all;
}

.robot-icon-content {
  position: relative;
}

.robot-icon-content::after {
  border-radius: 50% / 50%;
  background: #eee;
  animation: shadow 0.5s linear infinite;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 55px;
  margin: 0 auto;
  width: 100px;
  height: 50px;
  box-shadow: 0px 0px 10px #ddd;
}
.robot-icon-instrution {
  width: 250px;
  animation: rotate 0.5s linear infinite;
}

#__vconsole {
  display: none;
}

@keyframes shadow {
  0%,
  100% {
    transform: scaleX(1);
  }
  50% {
    transform: scaleX(1.2);
  }
}

@keyframes rotate {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(10px);
  }
  50% {
    transform: translateY(20px) scale(1.1, 0.9);
  }
  75% {
    ransform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}

.labtests-introduction {
  padding-left: 40px;
  padding-right: 40px
}

.labtest-container {
  text-align: left;
  padding-left: 40px;
  padding-right: 20px;
}

.labtest-name {
}

.labtest-information {
}
