.home-content {
  padding: 40px 20px;
  display: flex;
  justify-content: space-around;
}

.home-content-video-intro {
  width: 30%;
}

.home-content-product-intro {
  width: 30%;
  text-align: center;
}

.home-content-factory-intro {
  width: 30%;
  font-size: 18px;
}

.img-product {
  margin-bottom: 20px;
}

.content-bold {
  font-weight: bold;
}

.btn-taste {
  margin-top: 40px;
}