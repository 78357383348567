.content {
  padding: 0 200px 0 100px;
}

.cannot-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flex-layout {
  display: flex;
  margin-bottom: 50px;
  flex-wrap: wrap;
}

.robot-icon {
  width: 200px;
  height: 250px;
}

.user-icon {
  width: 200px;
}

.audio-icon {
  width: 900px;
  height: 100%;
}

.message-text {
  font-family: PingFangSC-Semibold;
  font-size: 64px;
  letter-spacing: -1.79px;
  text-align: left;
  flex: 4;
  padding: 25px;
  position: relative;
}

.question-text {
  color: #000000;
  background: #ffffff;
  margin-left: 60px;
}
.question-type-text {
  width:140px;
  font-size: 32px;
  background-color: #2979ff;
  border-radius:32px;
  color: #fff;
  padding: 0 20px;
}

.answer-text {
  color: #ffffff;
  background: #2979ff;
  margin-right: 60px;
  position: relative;
}

.btn-skip {
  background: #ffba48!important;
  border: none!important;
  border-radius: 86px!important;
  width: 343px;
  color: #2979ff!important;
  font-weight: bold!important;
  font-size: 64px!important;
  height: 140px!important;
  margin-left: 50px;
}

.radio-answer {
  border: 5px solid #2979ff;
  background: #ffffff;
  border-radius: 73px;
  height: 100px;
  line-height: 100px;
  padding: 0 60px 0 70px;
  font-family: PingFangSC-Semibold;
  font-size: 44px;
  color: #2979ff;
  letter-spacing: -1.67px;
  text-align: center;
  margin: 0 40px 40px 0;
}

.radio-answer-active {
  background: #2979ff!important;
  color: #ffffff!important;
}

.answer-text:before, .answer-text:after {
  display: inline-block;
  content: '';
  position: absolute;
  border: 20px solid transparent;
}

.answer-text:before {
  border-bottom-color: #2979ff;
  width: 10px;
  height: 0;
  right: -80px;
  top: 50px;
  border-left: 60px solid #2979ff;
  border-top: 35px solid transparent;
  border-bottom: 60px solid transparent;
}

/*
新增单选，多选样式
*/
.radio-answer-shape {
  width: 44px;
  height: 44px; 
  border: 4px solid #2979ff;
  border-radius: 100%;
  position: absolute;
  top: 30px;
  left: 20px;
  z-index: 1;
}
.radio-answer-shape-select {
  border: 4px solid #fff;
  background-color: #fff;
}
.radio-answer-shape-inner {
  width: 20px;
  height: 20px; 
  background-color: #2979ff;
  border-radius: 100%;
  position: absolute;
  top: 42px;
  left: 32px;
  z-index: 2;
}
.checkbox-answer-shape {
  width: 44px;
  height: 44px; 
  border: 4px solid #2979ff;
  border-radius: 2px;
  position: absolute;
  top: 35px;
  left: 25px;
  z-index: 1;
}
.checkbox-answer-shape-select {
  border: 4px solid #fff;
}
.checkbox-answer-shape-inner {
  position: absolute;
  top: 30px;
  left: 35px;
  font-size: 32px;
  z-index: 2;
  color: #fff;
}

/*
*友情提示样式
*/
.message-friendly-remind {
  flex: 2;
}
.message-friendly-remind-title {
  font-weight: bold;
  font-size: 24px;
}
.message-friendly-remind-text {
  font-size: 24px;
}