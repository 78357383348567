.welcome-page
  width 100%
  text-align center
  min-width 1200px
  .title 
    font-family PingFangSC-Semibold
    font-size 64px
    color #000000
    letter-spacing -1.79px
    text-align left
  .subtitle 
      font-family PingFangSC-Semibold
      font-size 64px
      color #000000
      letter-spacing -1.79px
      text-align left
  .btn-start 
      margin 74px auto 20px
      background #2979ff
      border-radius 78.5px!important
      /* width 350px */
      padding 0 70px!important
      height 158px!important
  .btn-start span 
      font-family PingFangSC-Semibold
      font-size 64px
      color #ffffff
      letter-spacing -1.79px
      text-align left
