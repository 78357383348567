.nav {
  overflow: hidden;
}

.home-page-button {
  float: right;
  padding: 15px;
}

.logo {
  float: left;
  margin: 10px;
  width: 300px;
}
